import axios from 'axios'
import router from '../router';
import store from '../store';
import {
  Loading,
  Message
} from 'element-ui';
const baseUrl = 'https://www.zgshjsw.cn/api/';


let queryRecord = {};
let loading;
let loadingCount = 0;
//内存中正在请求的数量
console.log(store, '当前状态机');
const addLoading = () => {
  // 开始请求时，添加数量，并且设置为显示loading
  loadingCount++;
  store.commit('SET_SHOWLOADING', true);
};
const isCloseLoading = () => {
  // 请求完成或者请求失败时,减少正在请求的数量,并且判断是否还有未完成的请求,如果没有了,则设置为隐藏loading
  loadingCount--;
  if (loadingCount <= 0) {
    store.commit('SET_SHOWLOADING', false);
  }
};



export function initRequest (url, params = {}) {
  // 防抖
  // let now = +new Date();
  // let last = queryRecord[url] || 0;
  // if (now - last <= 400) {
  //   return new Promise((resolve, reject) => {
  //     reject('请求过于频繁');
  //   });
  // }
  // queryRecord[url] = now;
  // console.log(encrypted,"加密后数据");
  let formData = new FormData();
  for (let key in params) {
    formData.append(key, params[key]);
  }
  let opt = {
    url: url,
    method: "POST",
    data: formData,
    headers: {
      "Encrypt-Type": '0',
      Authorization: localStorage.getItem('token') || ''
    },
  }
  //接口请求
  addLoading();
  return new Promise((reslove, reject) => {
    axios(opt)
      .then(res => {
        isCloseLoading();
        let response = res.data;
        if (response.code == 0) {
          Message.error({ message: response.msg });
        }
        if (response.code == -1) {
          Message.error({ message: response.msg });
          router.replace('/login');
        }
        // 判断是否加密
        if (response.data && response.data.encrypted) {
          let encryptedArr = response.data.encrypted;
          let data = [];
          encryptedArr.forEach(v => {
            data.push(DecryptObj.decrypt(v));
          });
          response.data = JSON.parse(data.join(''));
        }
        reslove(response);
      })
      .catch(err => {
        isCloseLoading();
        reject(err)
      })
      .finally(() => {
        isCloseLoading();
      })
  })
}